import { cn } from "utils/utils";

export function FileSearch({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn("size-8 shrink-0", className)}
      fill="none"
    >
      <path
        stroke="#14B480"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18.667 14.666h-8M13.334 20h-2.667M21.334 9.333H10.667m16 4.667V9.066c0-2.24 0-3.36-.436-4.215a4 4 0 00-1.748-1.749c-.856-.435-1.976-.435-4.216-.435h-8.534c-2.24 0-3.36 0-4.215.435a4 4 0 00-1.749 1.749c-.436.855-.436 1.975-.436 4.215v13.867c0 2.24 0 3.36.436 4.216a4 4 0 001.749 1.748c.855.436 1.975.436 4.215.436h3.6m14 0l-2-2M28.668 24a4.667 4.667 0 11-9.333 0 4.667 4.667 0 019.333 0z"
      ></path>
    </svg>
  );
}
