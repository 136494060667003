import { cn } from "utils/utils";

export function User({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn("size-8 shrink-0", className)}
      fill="none"
    >
      <path
        className="stroke-current"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M26.667 28c0-1.86 0-2.791-.23-3.548a5.334 5.334 0 00-3.555-3.556c-.757-.23-1.688-.23-3.549-.23h-6.666c-1.86 0-2.791 0-3.548.23a5.333 5.333 0 00-3.556 3.556c-.23.757-.23 1.687-.23 3.548M22 10a6 6 0 11-12 0 6 6 0 0112 0z"
      ></path>
    </svg>
  );
}
