import { cn } from "utils/utils";

export function PriceNegociation({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn("size-8 shrink-0", className)}
      fill="none"
    >
      <path
        className="stroke-current"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M28 15.333c0 6.26-5.074 11.334-11.334 11.334-1.435 0-2.809-.267-4.073-.754a3.84 3.84 0 00-.438-.155c-.09-.02-.156-.03-.249-.033-.094-.004-.197.007-.404.028l-6.828.706c-.651.067-.977.101-1.169-.016a.667.667 0 01-.311-.466c-.035-.222.12-.51.432-1.086l2.18-4.037c.18-.332.27-.498.31-.658.04-.158.05-.272.038-.434-.013-.165-.086-.378-.23-.806a11.32 11.32 0 01-.591-3.623C5.333 9.074 10.407 4 16.666 4 22.926 4 28 9.074 28 15.333z"
      ></path>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        className="stroke-current"
        d="M21.334 10.473a6.375 6.375 0 100 9.721m-12-2.86h7.5m-7.5-4h7.5"
      ></path>
    </svg>
  );
}
