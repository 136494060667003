import { cn } from "utils/utils";

export function BankNote({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn("size-8 shrink-0", className)}
      fill="none"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        className="stroke-current"
        d="M8 14.667V20m16-8v5.334m-1.334-12c3.265 0 5.031.5 5.91.887.117.051.175.077.344.238.102.097.286.38.334.512.08.22.08.34.08.58v14.33c0 1.212 0 1.818-.183 2.13-.184.316-.363.464-.709.585-.34.12-1.026-.012-2.4-.276-.96-.184-2.1-.32-3.376-.32-4 0-8 2.667-13.333 2.667-3.265 0-5.03-.5-5.91-.887-.116-.052-.175-.078-.344-.239a1.952 1.952 0 01-.333-.512c-.08-.22-.08-.34-.08-.58V10.12c0-1.212 0-1.818.182-2.13.185-.316.363-.463.71-.585.34-.12 1.026.012 2.399.276.961.185 2.1.32 3.376.32 4 0 8-2.667 13.333-2.667zM19.334 16a3.333 3.333 0 11-6.667 0 3.333 3.333 0 016.667 0z"
      ></path>
    </svg>
  );
}
