import { cn } from "utils/utils";

export function Weight({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn("size-8 shrink-0", className)}
      fill="none"
    >
      <path
        // stroke="#14B480"
        strokeLinecap="square"
        strokeWidth="2"
        className="stroke-current"
        d="M20 8a4 4 0 00-8 0m-4.763 5.974c.272-1.181.409-1.771.73-2.212a2.668 2.668 0 011.108-.882c.501-.214 1.107-.214 2.32-.214h9.21c1.213 0 1.819 0 2.32.214.442.189.825.494 1.108.882.322.44.458 1.03.73 2.212l2.031 8.8c.412 1.785.618 2.678.391 3.379a2.666 2.666 0 01-1.168 1.468c-.632.379-1.548.379-3.38.379H9.363c-1.832 0-2.748 0-3.38-.379a2.667 2.667 0 01-1.168-1.468c-.227-.701-.021-1.594.391-3.38l2.03-8.8z"
      ></path>
    </svg>
  );
}
